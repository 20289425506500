<template lang="">
    <div>
        <FeatureDetails/>
    </div>
</template>
<script>
export default {
    components:{
        FeatureDetails: () => import('@/components/manage/feature/FeatureDetails.vue'),
    }
}
</script>
<style lang="">
    
</style>